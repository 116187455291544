import type {
  DtoProgression,
  ModelsMinigameProgressionMap,
} from '@lp-lib/api-service-client/public';
import { type Block, BlockType } from '@lp-lib/game';

import { type Game } from '../../../types/game';
import { BlockKnifeV2Utils } from '../../GameV2/Shared';
import { VoiceOverUtils } from '../../VoiceOver/utils';

export class TrainingEditorUtils {
  static IsBlockRenderRequired(block: Block) {
    switch (block.type) {
      case BlockType.SLIDE:
        return VoiceOverUtils.IsDialogueRequiresRender(block.fields.dialogue);
      case BlockType.CODE_CANVAS:
        return VoiceOverUtils.IsDialogueRequiresRender(block.fields.dialogue);
      case BlockType.SCENARIO:
        return VoiceOverUtils.IsDialogueRequiresRender(block.fields.question);
      default:
        return false;
    }
  }

  static IsBlockRendering(block: Block) {
    switch (block.type) {
      case BlockType.SLIDE:
        return VoiceOverUtils.IsDialogueRendering(block.fields.dialogue);
      case BlockType.CODE_CANVAS:
        return VoiceOverUtils.IsDialogueRendering(block.fields.dialogue);
      case BlockType.SCENARIO:
        return VoiceOverUtils.IsDialogueRendering(block.fields.question);
      default:
        return false;
    }
  }

  static PreviewOverworldProgression(
    games: Game[],
    selectedGameIndex: number
  ): DtoProgression {
    return {
      id: '',
      uid: '',
      createdAt: '',
      updatedAt: '',
      gamePackId: '',
      progress: games.reduce((acc, game, index) => {
        if (index > selectedGameIndex) return acc;
        if (index === selectedGameIndex) {
          acc[game.id] = {
            completedAt: null,
            minigameId: game.id,
            unlockedAt: game.createdAt,
          };
          return acc;
        }
        acc[game.id] = {
          completedAt: new Date().toISOString(),
          minigameId: game.id,
          unlockedAt: game.createdAt,
        };
        return acc;
      }, {} as ModelsMinigameProgressionMap),
      blockProgressPct: 0,
    };
  }

  static BlockTitle(block: Block): string {
    const blockSummary = BlockKnifeV2Utils.Summary(block);
    return (
      block.fields.title || blockSummary.title || blockSummary.prettyTypeName
    );
  }
}
